import "./Footer.css";

function Footer() {
  return (
    <footer>
      <span className="copyright">
        Copyright © 2023 Mumbo Solutions. All rights reserved.
      </span>
    </footer>
  );
}
export default Footer;
